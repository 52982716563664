<!-- =========================================================================================
    File Name: CourseList.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vx-card bg-dark p-8">
    <div class="flex flex-wrap items-center">
      <div class="flex-grow">
        <vs-dropdown class="cursor-pointer mr-2" vs-trigger-click>
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} -
              {{ labData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : labData.length }} of
              {{ totalCourses }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item
              @click="
                gridApi.paginationSetPageSize(10);
                loadProductBuffer();
              "
            >
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item
              @click="
                gridApi.paginationSetPageSize(20);
                loadProductBuffer();
              "
            >
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item
              @click="
                gridApi.paginationSetPageSize(50);
                loadProductBuffer();
              "
            >
              <span>50</span>
            </vs-dropdown-item>
            <vs-dropdown-item
              @click="
                gridApi.paginationSetPageSize(100);
                loadProductBuffer();
              "
            >
              <span>100</span>
            </vs-dropdown-item>
            <vs-dropdown-item
              @click="
                gridApi.paginationSetPageSize(150);
                loadProductBuffer();
              "
            >
              <span>150</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>

        <!-- <vs-button @click="addNewData" class="ml-2 mr-2 round" color="primary" type="gradient">Create New Account</vs-button> -->
      </div>

      <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
      <vs-input
        @input="updateSearchQuery"
        class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
        placeholder="Search..."
        v-model="searchQuery"
      />
      <vs-button color="primary" icon-pack="feather" icon="icon-filter" class="ml-2" type="gradient" @click="filterPopup = !filterPopup"
        >Open Filter
      </vs-button>
      <!--- <vs-button @click="gridApi.exportDataAsCsv()" class="mb-4 md:mb-0">Export as CSV</vs-button> --->
    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      :animateRows="true"
      :columnDefs="columnDefs"
      :components="components"
      :defaultColDef="defaultColDef"
      :enableRtl="$vs.rtl"
      :floatingFilter="true"
      :gridOptions="gridOptions"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :rowData="labData"
      :suppressPaginationPanel="true"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      colResizeDefault="shift"
      ref="agGridTable"
    >
    </ag-grid-vue>

    <vs-pagination :total="totalPages" v-model="currentPage" />
  </div>
</template>

<script>
import TableName from './components/TableName.vue';
import TableActions from './components/TableActions.vue';
import TableID from './components/TableID.vue';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
    TableName,
    TableActions,
    TableID,
  },
  props: {
    courseId: {
      type: String,
      default: '',
      required: false,
    },
    filterCourse: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      showArchived: false,

      labData: [],
      totalCourses: 0,

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            const value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          width: 175,
          cellRendererFramework: 'TableID',
        },
        {
          headerName: 'Username',
          field: 'username',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableID',
        },
        {
          headerName: 'Password',
          field: 'password',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableID',
        },
        {
          headerName: 'Actions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'TableActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        TableName,
        TableActions,
        TableID,
      },
      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    addNewData() {
      this.$router.push('/a/labs/accounts/new');
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery() {
      this.currentPage = 0;
    },
    loadProductBuffer(page = 0) {
      if (page === 0) {
        page = this.currentPage;
      }

      this.$httpLabs
        .get(`labs/accounts?page=${page}&pageSize=${this.paginationPageSize}&search=${this.searchQuery}`)
        .then((response) => {
          if (response.data) {
            const data = response.data;

            this.labData = data.rows;
            this.totalPages = data.totalPages;
            this.totalCourses = data.total;

            this.gridApi.sizeColumnsToFit();
          }
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
  created() {
    this.loadProductBuffer();
  },
};
</script>

<style lang="scss">
.theme-dark {
  #LabFilterPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
